<template>
    <section class="largeCard computer">
        <div class="page-header mb-0">
        <h3 class="page-title">
        Nombre de voitures: {{itemsNumber}}
        </h3>
            <nav class="d-flex justify-content-end flex-row computer">
                <ol class="breadcrumb">
                        <b-input-group>
                            <b-form-input size="md" id="search_Bar" v-if="activeSearch" v-model="search" placeholder="Rechercher une voiture"></b-form-input>
                            <b-input-group-text slot="append" class="">
                            <span><i class="fa fa-search"></i></span>
                            </b-input-group-text>
                        </b-input-group>
                </ol>
            </nav>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <span v-if="!activePerpage">
                        <!-- n'affiche rien -->
                    </span>
                    <span v-else class="computer">
                        <b-form-select size="md" variant="gradient-primary" v-model="perPage" style="width:auto">
                            <b-form-select-option value="3">3</b-form-select-option>
                            <b-form-select-option value="5">5</b-form-select-option>
                            <b-form-select-option value="10">10</b-form-select-option>
                            <b-form-select-option value="25">25</b-form-select-option>
                            <b-form-select-option value="50">50</b-form-select-option>
                        </b-form-select>
                    </span>

                    <b-pagination class="separated pagination-danger pl-2 computer"
                    :total-rows="rows"
                    v-model="currentPage"
                    :per-page="perPage"
                    aria-controls="id">
                    </b-pagination>
                    <!-- <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search"></b-form-input> -->
                </ol>
            </nav>
        </div>
        <div class="row computer">
                <div class="col-md-12 pt-2 border px-0" v-for="(item, itemid) in paginateItems" :key="itemid">
                    <div class="card rounded shadow-none">
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="user-avatar mb-auto">
                                        <img :src="item.thumbPhoto" height="150" alt="profile image" class="profile-img" loading=lazy>
                                        <span class="edit-avatar-icon img-logo"><img src="@/assets/images/import/logo-mini.png" alt="logo"></span>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <h4>{{item.titre}}</h4>
                                        </div>
                                        <div class="col-3">
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Référence
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.reference}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Marque
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.marque}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Modèle
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.modele}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                            Carrosserie
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.carrosserie}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                            Kilométrage
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.kilometrage}} Km
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-4">
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Couleur
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.couleur}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Transmission
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.boite_de_vitesse}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Nombre de places
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.nbPlaces}}
                                                </span>
                                            </p>
                                            <p class="clearfix">
                                                <span class="float-left">
                                                Nombre de portes
                                                </span>
                                                <span class="float-right text-muted">
                                                {{item.nbPortes}}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2 d-flex justify-content-center align-self-center">
                                            <h3 class="mb-5" v-if="item.offre">{{item.offre.prix}} €</h3>
                                        </div>
                                        <div class="col-3 d-flex justify-content-center align-self-center">
                                            <button class="btn btn-outline-dark mb-5" @click="showDetails(item)">Voir la fiche complète</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'large-card',
    data () {
        return {
            currentPage: 1,
            search:''
        }
    },
    props: {
        activeSearch: Boolean,
        activePerpage: Boolean,
        // search: String,
        perPage: {
            default: 5
        },
        id: String,
        items: Array,
        // col: String,
        // name: String,
        // price: Number
    },
    computed: {
        rows () {
            return this.items.length
        },
        searchResult() {
            return this.search ? this.items.filter(
                item => item.titre.toLowerCase().includes(this.search.toLowerCase())
            )
            :this.items
        },
        paginateItems () {
            return this.searchResult.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },
        itemsNumber() {
            return this.searchResult.length
        }
        
    },
    watch: {
        // searchResult(value) {
        //     console.log(value);
        // }
    },
    methods: {
        showDetails(item) {
            // console.log(item)
            const reference= item.reference
            this.$router.push({
                name:'vehiculeDetails',
                params: { reference }
            })
            // console.log(item.reference);
        }
        // sortPhoto () {
        //     for (let item of this.items) {
        //         let sortPhoto=item.photos.photo.slice().sort()
        //         item.thumbPhoto = sortPhoto [0]
        //     }
        // }
    }
}
</script>

<style scoped>
.img-logo {
border: none !important;
background-color: #faebd700  !important;
right: none;
left: 2%;
bottom: 2%
}

img{
    width:100%;
    height: auto;
}

.card{
    transition: transform .2s; /* Animation */
}
#search_Bar{
    width: 20rem;
}
.largeCard{
    min-height: 800px;
}

.card:hover{
    /* background: #66c430;
    color: #fff; */
    transform: scale(1.05);
}
/* .card:hover .text-muted{
    color: #fff !important;
}
.card .text-muted:hover {
    color: #fff !important;
} */

</style>