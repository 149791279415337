<template>
    <section>
        <title-banner></title-banner>
        <div class="content-wrapper">
            <!-- <h2 class="text-center">LISTE DES VEHICULES</h2>
            <p class="text-center mb-4">en-stock ou sur commande - Révisé et garanti</p> -->
            <large-card :items="cars" :activePerpage="true" :activeSearch="true"/>
            <simple-card :items="cars" :activeSearch="true" :activePerpage="true"/>
        </div>
    </section>
</template>

<script>
import largeCard from "../components/largeCard.vue"
import SimpleCard from '../components/simpleCard.vue'
import titleBanner from '../components/titleBanner.vue'
const axios= require('axios').default
export default 
  {
    name: "vehicules",
    components: { largeCard, SimpleCard, titleBanner },
    data() {
         return {
            cars:[],
            photos: []
        }
    },
    methods: {
        getCars () {
            axios.get('vehicules', {
                withCredentials: true
            })
            .then(resCar => {
                // console.log({resCar})
                this.cars = resCar.data.result

                for (let item of this.cars) {
                // let sortPhoto=item.photos.photo.slice().sort()
                // item.thumbPhoto = sortPhoto [0]
                item.thumbPhoto = item.photos.photo[0]
                }
                // console.log('thumb photo',this.cars);
            })
            .catch(errCar => {
                console.log(errCar)
            })
        }
    },
    mounted () {
        this.getCars()
    }
}
</script>

<style scoped>
.content-wrapper {
    max-width: 1600px
}

</style>