<template>
<section class="mobile">
    <div class="page-header">
        <h3 class="page-title">
        {{title}}
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <span v-if="!activePerpage">
                    <!-- n'affiche rien -->
                </span>
                <span v-else class="computer">
                    <b-form-select size="md" variant="gradient-primary" v-model="perPage" style="width:auto">
                        <b-form-select-option value="3">3</b-form-select-option>
                        <b-form-select-option value="5">5</b-form-select-option>
                        <b-form-select-option value="10">10</b-form-select-option>
                        <b-form-select-option value="25">25</b-form-select-option>
                        <b-form-select-option value="50">50</b-form-select-option>
                    </b-form-select>
                </span>

                <b-pagination class="separated pagination-danger pl-2 computer"
                :total-rows="rows"
                v-model="currentPage"
                :per-page="perPage"
                aria-controls="id">
                </b-pagination>
                <!-- <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search"></b-form-input> -->
            </ol>
            <ol class="breadcrumb">
                <b-form-group>
                  <b-input-group>
                    <b-form-input size="md" v-if="activeSearch" v-model="search" placeholder="Rechercher une voiture"></b-form-input>
                    <b-input-group-text slot="append" class="">
                      <span><i class="fa fa-search"></i></span>
                    </b-input-group-text>
                  </b-input-group>
                </b-form-group>
            </ol>
        </nav>
    </div>
    <div class="row product-item-wrapper" v-if="searchResult.length>0">
        <div class="col-md-6 col-sm-12 product-item stretch-card" :class="col" v-for="(item, i) in paginateItems" :key="i"
        id="id"
        :current-page="currentPage"
        :per-page="perPage">
            <div class="card">
                <div class="card-body">
                    <div class="product-img-outer">
                        <img class="product_image" :src="item.thumbPhoto" alt="prduct image" @click="showDetails(item)" style="cursor:pointer">
                    </div>
                    <p class="product-title" @click="showDetails(item)" style="cursor:pointer">{{item.titre}}</p>
                    <h2 class="product-price orange " v-if="item.offre">{{item.offre.prix}} €</h2>
                    <p class="">Année: 2012</p>
                    <p class="product-description d-flex justify-content-between border-top pt-4 text-muted">
                        <span> <i class="mdi mdi-gauge"></i> {{item.kilometrage}} km</span>
                        <span> <i class="mdi mdi-gas-station"></i> {{item.energie}}</span>
                        <span> <i class="">
                            <img src="@/assets/images/import/ets.png" class="icon" alt="gearbox">
                            </i> {{item.boiteDeVitesse}}</span>
                    </p>
                </div>
            </div>
        </div>
        
    </div>
    <div v-else>
        <h4>
            Il n'y a pas de voiture correspondante à la recherche
        </h4> 
    </div>
    <div class="d-flex justify-content-center">
        <button v-if="perPageShow && paginateItems.length>0" class="btn btn-link" @click="seeMore()">Voir plus<i class="mdi mdi-arrow-down-drop-circle-outline "></i></button>
        <button v-if="paginateItems.length>0" class="btn btn-link" @click="scrollTop()">Début de la page  <i class="mdi mdi-arrow-up-drop-circle-outline "></i></button>
    </div>
    <!-- <div class="col d-flex justify-content-end mobile">
        <b-pagination class="separated pagination-danger pl-2"
        :total-rows="rows"
        v-model="currentPage"
        :per-page="perPage"
        aria-controls="id">
        </b-pagination>
        <span v-if="!activePerpage">
        </span>
    </div> -->
</section>
</template>
<script>
export default {
    name: 'paginateCard',
    data() {
        return {
            currentPage: 1,
            search:'',
            perPage: 5,
            perPageShow: true
        }
    },
    props: {
        activeSearch: Boolean,
        activePerpage: Boolean,
        id: String,
        items: Array,
        title: String,
        col: String,
        name: String,
        price: Number
    },
    computed:{
        rows () {
            return this.items.length
        },
        searchResult() {
            return this.search ? this.items.filter(
                item => item.titre.toLowerCase().includes(this.search.toLowerCase())
            )
            :this.items
        },
        paginateItems () {
            return this.searchResult.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        }
        // upperCase(name) {
        //     return name.charAt(0).toUpperCase()+ name.slice(1)
        // }
    },
    watch: {
        // searchResult(value) {
        //     // console.log(value);
        // }
    },
    methods: {
        showDetails(item) {
            // console.log(item)
            const reference= item.reference
            this.$router.push({
                name:'vehiculeDetails',
                params: { reference }
            })
        },
        seeMore() {
            console.log(this.perPage)
            this.perPage = this.perPage + 5
            // console.log(this.perPage)

            this.paginateItems.length<this.perPage ? this.perPageShow=false : this.perPageShow
        },
        scrollTop () {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .computer {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .mobile {
      display: none !important
  }
}
.icon{
    width: auto;
    height: 20px;
}
.btn.btn-link {
    color: black
}
</style>